const PANIER = [
    {
      page: 3,
      lib: "Première énigme !",
      texteIndice: ["Ce sont des coordonnées", "MOT - lettre", "Bocal"]
    },
    {
      page: 4,
      lib: "A bien voir...",
      texteIndice: [
        "voir commerce",
        "restaurant au  nom  bleu",
        "Hippocampe"
      ]
    },
    {
      page: 7,
      lib: "Liaisons dangereuses",
      texteIndice: [
        "chien = cabot",
        "reliez",
        "La  lettre   T"
      ]
    },
    {
      page: 10,
      lib: "Dessins trompeurs",
      texteIndice: [
        "L'énigme donne les 6 premières lettres, complétez ensuite",
        "Utilisez  la  fiche alphabet fournie",
        "Pavill (on Daviel)"
      ]
    },
    {
      page: 11,
      lib: "Ce n'est pas un jeu, c'est une énigme",
      texteIndice: [
        "réutilisez",
        "l'alphabet",
        "Arche"
      ]
    },
    {
      page: 14,
      lib: "Diapason",
      texteIndice: ["Chanson", "Hymne", "Allons enfants de la patrie..."]
    },
    {
      page: 16,
      lib: "Invitation",
      texteIndice: [
        "Regardez  par la fenetre  !",
        "Et retournez",
        "Projet"
      ]
    },
    {
      page: 18,
      lib: "Goûts & couleurs",
      texteIndice: [
        "Rose, noir",
        "Vert, bleu",
        "Lettres entourées. Réponse: suivre"
      ]
    },
    {
      page: 21,
      lib: "Graffiti poête",
      texteIndice: [
        "Suivez l’indication du dessin",
        "Tournez",
        "Pagnol"
      ]
    },
    {
      page: 23,
      lib: "La plaque",
      texteIndice: [
        "Trouvez la plaque murale",
        "Comparez et faites pareil",
        "Demandez la vérité"
      ]
    },
    {
      page: 25,
      lib: "Des fenêtres",
      texteIndice: [
        "Quand c'est bouché la lumière ne passe pas",
        "Gardez les vrais fenêtres pour les vrais lettres",
        "Miaulement"
      ]
    },
    {
      page: 27,
      lib: "Des hôtels",
      texteIndice: [
        "3: Ville (Hôtel de.)",
        "5: Vielle charité",
        "Olive"
      ]
    },
    {
      page: 28,
      lib: "Coin coin",
      texteIndice: ["Faux noms", "Des plaques inversés ?", "bonneterie"]
    },
    {
      page: 31,
      lib: "Promenade dans le temps",
      texteIndice: [
        "Trouvez la maquette",
        "Suivez le chemin sans bouger !",
        "La terrasse funéraire"
      ]
    },
    {
      page: 33,
      lib: "Une énigme marquante",
      texteIndice: ["Deux points communs", "Des commerces", "Trabuc"]
    },
    {
      page: 34,
      lib: "Un peu de géographie",
      texteIndice: [
        "Trouvez une caisse en bois",
        "Avec un gros globe terrestre dedans",
        "La lettre A"
      ]
    },
    {
      page: 36,
      lib: "Avant dernière énigme !",
      texteIndice: [
        "L'arme était Excalibur",
        "L'epée plantée",
        "Le monde à reconnu..."
      ]
    },
    {
      page: 37,
      lib: "Dernière énigme !",
      texteIndice: [
        "Trouvez les points communs",
        "A chaque syllabe de la réponse correspond un point commun",
        "Jo est à Porto Vecchio !"
      ]
    }
  ];

export default PANIER;