const AVI2 = [
    {
      page: 4,
      lib: "Première énigme !",
      texteIndice: ["Gardez les lettres", "ab - a = b", "Sacs"]
    },
    {
      page: 6,
      lib: "L'Hôtel des Monnaies",
      texteIndice: [
        "Comparez avec le réel",
        "Une erreur sur la photo",
        "Chat"
      ]
    },
    {
      page: 8,
      lib: "Le palais des Papes",
      texteIndice: [
        "Des exemples sont données sur l'énigme",
        "Fenêtres",
        "Arrivée (le nombre de fenetres dans chaque arche donne une lettre)"
      ]
    },
    {
      page: 11,
      lib: "La gravure",
      texteIndice: [
        "Chantez maintenant",
        "Ces gens sont sur un pont",
        "La lettre D"
      ]
    },
    {
      page: 13,
      lib: "La vigne",
      texteIndice: [
        "Deux outils à combiner, déjà fournis",
        "Le crayon et la vigne",
        "Cage (Entourez le crayon)"
      ]
    },
    {
      page: 15,
      lib: "Le jardin",
      texteIndice: ["Cherchez la fraicheur", "La fontaine", "Meuse (plaque sous cygne)"]
    },
    {
      page: 16,
      lib: "Le nom de la rue",
      texteIndice: [
        "Proche de la plaque",
        "Gravure sous plaque de rue",
        "Rue des ciseaux d'or"
      ]
    },
    {
      page: 18,
      lib: "Le blason",
      texteIndice: [
        "Eliminez les mauvaises",
        "Les mauvaises couleurs",
        "Forums"
      ]
    },
    {
      page: 20,
      lib: "Le blason",
      texteIndice: [
        "Formez un seul mot",
        "Un restaurant de la palce",
        "Cloitre"
      ]
    },
    {
      page: 22,
      lib: "Les clés",
      texteIndice: [
        "Outil fournis en plus du carnet",
        "Pliez les clés",
        "Boucherie"
      ]
    },
    {
      page: 24,
      lib: "La tour",
      texteIndice: [
        "Trouvez la tour, gardez les lettres rouges",
        "Lisez de haut en bas, des lettres se répètent",
        "Genent"
      ]
    },
    {
      page: 27,
      lib: "Mots croisés",
      texteIndice: [
        "1: Clément",
        "2: Doms, 3: Salamandre",
        "Moustaches"
      ]
    },
    {
      page: 29,
      lib: "Indice",
      texteIndice: ["Transparence", "Combien d'étoiles ? Mais pas dans le ciel", "Trois (étoiles à l'hôtel)"]
    },
    {
      page: 31,
      lib: "Le jardin",
      texteIndice: [
        "Melangez",
        "Jaune et bleu donne du vert",
        "Jarre (car fond vert)"
      ]
    },
    {
      page: 33,
      lib: "Ginette & Marcel",
      texteIndice: ["Nord vaut 'N'", "Ginette & Marcel sont au nord", "Nonnes"]
    },
    {
      page: 35,
      lib: "Les corps saints",
      texteIndice: [
        "Au coin d'un immeuble je suis",
        "Coin rue Agricol Perdiguier",
        "Un pont"
      ]
    },
    {
      page: 37,
      lib: "Les arches",
      texteIndice: [
        "Trouvez la plaque qui ressemble au dessin",
        "Six lettres à déduire",
        "Maison"
      ]
    }
  ];

export default AVI2;