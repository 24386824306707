const PANIERB2BTEMP = [
    {
      page: 98,
      lib_page: "Mauvaise adresse ! Utilisez le QR Code fourni au début du jeu pour afficher les indices !",
      lib: "Mauvaise adresse ! Utilisez le QR Code fourni au début du jeu pour afficher les indices !",
      reponse: "XX",
      lib_win_title: "Bravo ! Félicitations !",
      lib_win_texte: "Votre temps a été enregistré. Retournez voir l'animateur."
    }
  ];

export default PANIERB2BTEMP;